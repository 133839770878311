import React from "react";
import { isActiveClass } from "./utils";
import ManBackImage from "./man_back.png";

export interface BodyPartProps {
    toggleBodyPart?: (bodyPart: string) => void
    activeParts: string[] | Record<string, "blue-dark" | "blue" | "blue-light">
}


export const ManBack: React.FunctionComponent<BodyPartProps> = ({
                                                                    toggleBodyPart:toggleBodyPartCallback,
                                                                    activeParts
                                                                }) => {
    const img = new Image();
    img.src = ManBackImage;


    const toggleBodyPart =(bodyPart: string) => {
        if(toggleBodyPartCallback)
            toggleBodyPartCallback(bodyPart);
    };

    return (

        <div className="bodyparts">
            <div className={"svg-container"}
                 style={{ backgroundImage: "url(" + ManBackImage + ")" }}>
                <svg
                    className="body"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="100%"
                    height="100%"
                    viewBox="0 -6 722 1364"
                    enableBackground="new 0 0 722 1370"
                    xmlSpace="preserve"
                >

                    <path
                        id="head_back"
                        onClick={() => toggleBodyPart("head_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "head_back"
                        )}`}
                        d="M414.3,156.1c3.1-19,9.4-66.1,10.5-83.7c1.3-21.7-29-55.3-61.3-55.3c-27.3,0-58.5,32-58,52.7
        c0.2,7.9,2,33,2.3,36.3c0.2,2.4,4.3,32,7.5,49.6C325.5,176.7,404.1,176.5,414.3,156.1z"
                    />
                    <path
                        id="neck_back"
                        onClick={() => toggleBodyPart("neck_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "neck_back"
                        )}`}
                        d="M461.3,228c-28-9.5-49-27.3-50-29.5s0.2-30.7,1.5-34.5c0.2-0.7,0.8-3.6,1.5-7.9c-10.2,20.4-88.8,20.6-99-0.4
                c1.2,7,2.3,12.1,3,12.9c1.6,2,2.9,28.4,0.5,31.5c-7.8,10.1-31.5,20.8-49.2,27.5C291.2,221.5,443.4,224.2,461.3,228z"
                    />
                    <path
                        id="back"
                        onClick={() => toggleBodyPart("back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "back"
                        )}`}
                        d="M502.5,353c2.8-16.7,6.3-37,4.1-48.7c-0.1-0.7-0.3-1.3-0.4-2c-3-11.3-7.7-52-44.8-74.3
                c-17.9-3.8-170.1-6.5-191.8-0.5C230.1,249,225,296.3,224,302s0.5,26.2,2.2,36.8s8.2,29.6,4.6,66.4c1.1,0,1.5,3.5,1.9,6.1
                s4.2,16.8,9.2,23s9.2,25,13.2,32.5c4.5,5.5,41.4,10.6,83.7,11.5c9.7,0.2,19.6-1.7,29.5-1.8c10,0,19.9,1.8,29.5,1.5
                c39.6-1.2,73-6.3,77.2-11.5c2.6-5.7,6.4-14.6,7.5-18.2c1.5-5,2.7-9.2,4.8-12.7s7.8-18.1,8.7-21.1s2.2-9.4,3.3-9.5
                C498.5,389.7,499.5,370.7,502.5,353z"
                    />
                    <path
                        id="loin"
                        onClick={() => toggleBodyPart("loin")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "loin"
                        )}`}
                        d="M398,478c-9.6,0.3-19.5-1.6-29.5-1.5c-9.9,0-19.8,2-29.5,1.8c-42.4-0.8-79.3-6-83.7-11.5c4,7.5,6.5,19,8.5,37.8
                s-2.2,32-3.2,37.8s-0.2,23.9,1.2,28c1.4,3.9,3.6,9-0.4,15.8c6.3-9.2,30.6-16.7,56.6-16.7c29.5,0,31,10.3,51.5,10.3
                c19,0,26-10,51.5-10.2c25.2-0.1,43.9,7.2,48.4,16.6c-0.5-1.3-0.9-2.2-1.1-2.6c-0.8-2.3-1.2-6.2-0.3-8.2s2.7-12.8,2.8-19
                s-3.7-30-4.7-34.8s1.7-28.5,2.3-33.3s3-14.7,4.3-16.8c0.4-0.6,1.3-2.5,2.4-4.8C471,471.6,437.6,476.8,398,478z"
                    />
                    <path
                        id="buttocks"
                        onClick={() => toggleBodyPart("buttocks")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "buttocks"
                        )}`}
                        d="M421,569.5c-25.5,0.1-32.5,10.2-51.5,10.2c-20.5,0-22-10.3-51.5-10.3c-26.1,0-50.4,7.5-56.6,16.7
                c-0.2,0.3-0.4,0.6-0.6,0.9c-6,9-12,51.8-11.5,64s-2.5,24-4,32.5c0,19,7.3,25.1,15.3,37.1c20.7,31.3,58.2,54.3,92.4,20.4
                c2.8-2.9,6.8-8.9,7.8-11.6s2-3.2,4.4-3.2s3.8,1.1,4.2,2.9s3.8,8.5,7.3,11.3c37.8,39.9,74.9,13,94.4-18.4
                c8.5-13.7,14.1-14.9,14.1-38.4c-1.6-8.4-3.4-18.1-4-21.7c-1.2-6.3-0.5-24.3-2.7-42.7c-1.6-13.7-6.1-25.6-8.5-31.7
                c-0.2-0.5-0.4-1-0.6-1.4C464.9,576.7,446.3,569.4,421,569.5z"
                    />
                    <path
                        id="shoulder_left_back"
                        onClick={() => toggleBodyPart("shoulder_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "shoulder_left_back"
                        )}`}
                        d="M224,302c1-5.8,6.1-53,45.5-74.5c-8.1,3.1-15,5.3-18.5,6.2c-8.3,2.2-41.9,9.4-53.5,19.5
                c-17.2,15-26,35-27.5,62.8c-0.7,13.3,0,25.8,0,34.5C179.8,326.2,204.2,324.3,224,302z"
                    />
                    <path
                        id="shoulder_right_back"
                        onClick={() => toggleBodyPart("shoulder_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "shoulder_right_back"
                        )}`}
                        d="M506.2,302.3c0.2,0.6,0.3,1.3,0.4,2c16,17.1,38.9,31,53.4,43.9c-0.1-0.1-0.2-0.3-0.3-0.4
                c0.2-20.2,4.8-50.8-12.2-78.5s-58.2-31.8-86.2-41.2C498.5,250.3,503.2,291,506.2,302.3z"
                    />
                    <path
                        id="arm_left_back"
                        onClick={() => toggleBodyPart("arm_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "arm_left_back"
                        )}`}
                        d="M226.2,338.8c-1.8-10.5-3.2-31-2.2-36.8c-19.8,22.3-44.2,24.2-54,48.5c-6.8,10.7-18.2,33.8-20,44
                s-4.5,20-7.2,27.8c-4,34.5,10.7,40.8,26.1,46.4c16.5,6.1,29.2,0.6,38.3-15.1c1.1-2.2,2.2-4.3,3.2-6.2c3.5-7,16.4-34.5,17.3-37.3
                s2.1-4.9,3.2-4.9C234.5,368.3,228,349.2,226.2,338.8z"
                    />
                    <path
                        id="arm_right_back"
                        onClick={() => toggleBodyPart("arm_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "arm_right_back"
                        )}`}
                        d="M561.6,468.7c14.5-5.3,29.1-11.1,27.7-40.2c-2-7.6-4.8-13-8-28.8c-3.7-18.3-14-40-21.2-51.6
                c-14.5-12.8-37.4-26.8-53.4-43.9c2.2,11.7-1.3,32-4.1,48.7c-3,17.7-4,36.7-3,52.1c1.2-0.1,2.7,2.9,4,5.7c1.1,2.4,15.8,35,21.1,46.3
                C533.7,470.1,546.3,474.3,561.6,468.7z"
                    />
                    <path
                        id="elbow_left_back"
                        onClick={() => toggleBodyPart("elbow_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "elbow_left_back"
                        )}`}
                        d="M168.9,468.7c-15.4-5.7-30.1-11.9-26.1-46.4c-2.8,7.8-1.8,15.2-6.5,23.5s-0.8,6.5-9.8,20
                c-4.2,6.3-9,20.1-13.2,35.5c-1.3,4.6-2.5,9.4-3.6,14.1c3.4-5.9,6.6-22.6,50.4-11c10.7,2.8,31.9,11.2,26.9,27.5
                c2.7-7.8,4.7-15.2,6.3-21.6c2.5-10.4,3.7-18,4-19.9c0.5-4.3,0.8-7.3,1.5-9.3s2.2-9.8,2.3-13.2c0.1-2.4,3.1-8.5,6-14.3
                C198.1,469.3,185.4,474.8,168.9,468.7z"
                    />
                    <path
                        id="elbow_right_back"
                        onClick={() => toggleBodyPart("elbow_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "elbow_right_back"
                        )}`}
                        d="M570.3,504.3c39.1-10.4,46.7,1.8,50.3,8.8c-0.2-1.4-0.4-2.7-0.6-3.8c-2.8-17.8-17.8-47-19.5-49.8
                s-8.2-16.5-10.2-26.8c-0.3-1.5-0.6-2.9-1-4.2c1.5,29.1-13.2,34.9-27.7,40.2c-15.3,5.6-27.9,1.4-37-11.7c0.8,1.7,1.4,2.9,1.7,3.5
                c2.2,4.2,2.2,4.8,2.2,7.8s2.8,14.2,4.1,19.5c1.2,5,0.1,15.9,7.6,38.9C542.6,514.4,561.3,506.7,570.3,504.3z"
                    />
                    <path
                        id="forearm_left_back"
                        onClick={() => toggleBodyPart("forearm_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "forearm_left_back"
                        )}`}
                        d="M160.2,504.3c-43.8-11.6-47,5.1-50.4,11c-3.1,13.1-5.6,25.9-6.7,35.2c-2.2,17.5-8.4,42.6-11.8,56.1
                c-0.9,6,6.2,9.7,17.6,14.5c11,4.7,23.7,4,26.3,0.2c10.1-17.1,26.8-37.5,39.9-61.5c4.9-9,8.9-18.7,12.1-27.9
                C192,515.5,170.9,507.1,160.2,504.3z"
                    />
                    <path
                        id="forearm_right_back"
                        onClick={() => toggleBodyPart("forearm_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "forearm_right_back"
                        )}`}
                        d="M621.7,621c8.9-3.7,15.1-6.8,17-10.8c-2-5.8-4-12.8-5.4-20.3c-3-15.9-9.6-57.1-12.7-76.9
                c-3.6-7-11.1-19.1-50.3-8.8c-9.1,2.4-27.7,10.2-30.3,22.4c0.5,1.4,0.9,2.8,1.4,4.3c9,26,30,55.2,45.5,79.5c3,4.6,5.5,8.9,7.6,12.7
                C600.6,625.1,612.9,624.7,621.7,621z"
                    />
                    <path
                        id="wrist_left_back"
                        onClick={() => toggleBodyPart("wrist_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "wrist_left_back"
                        )}`}
                        d="M108.8,621c-11.4-4.8-18.4-8.5-17.6-14.5c-1.2,4.8-2.1,8.2-2.2,9.1c-0.7,3.5-4.2,10.2-6.2,18.3
                c-0.4,1.7-2.8,3.5-5.5,4.5c3.7-0.7,44.6,18.4,45.2,20.5c-1-4-1.2-8,7-27c1.5-3.4,3.4-7,5.6-10.7C132.5,625.1,119.8,625.7,108.8,621z
                "
                    />
                    <path
                        id="wrist_right_back"
                        onClick={() => toggleBodyPart("wrist_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "wrist_right_back"
                        )}`}
                        d="M649.5,637.8c-3.5-0.5-4-8.2-5.2-12.2c-0.7-2.2-3.1-7.8-5.6-15.2c-1.9,4-8.1,7-17,10.8
                c-8.7,3.7-21.1,4.1-27.1,2.2c9.1,16.2,11.4,25.4,12.4,29.1c0.5,2.1,0.6,3.6,0.5,4.9c8.1-4.7,34.2-16.3,43.6-19.1
                C650.5,637.9,650,637.8,649.5,637.8z"
                    />
                    <path
                        id="hand_left_back"
                        onClick={() => toggleBodyPart("hand_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "hand_left_back"
                        )}`}
                        d="M77.3,638.5c-3.2,1.1-14.2,7-17.8,8.5s-5.8,6.5-10.2,9s-8.3,6-9,8.8s-5.5,4.3-5.5,7.3s2,5.3,6.9,6
                s12.6-8,14.1-9.5s2.5,0.5,1.8,2.3s-5.7,15-6.8,19.8c-1.2,4.8-3.8,17-4.5,21s-3,21-3.3,24s-3.3,15,1.2,18.3s7.8-2.3,9.8-7.7
                s1.5-11.8,2.7-14.5s4.3-19,6.3-22.5s2.8,1.2,1.7,4.2s-3.8,16.2-3.8,18.3s-1.8,14-2.5,18s-1.3,14,0,18.2s7.2,1.7,9-0.5
                s3.7-11.2,4.5-16.5s1-14.2,2.5-20s3.6-16.3,5.5-19.2s3.8,0.3,3.3,2.5s-2.3,9.5-4,16.3s-1.5,14.5-3,21.3s-3.2,12.3,0,15.8
                s6.5,0.8,8.5-1.7s4.3-13.3,5.7-21.8s4.7-21.2,5.8-25.2c1.2-4,3.5-7.8,5.3-7.5c1.8,0.3-0.2,6-1,9.2s-5,20.7-5.2,26.3s2.5,7.8,5.7,6.5
                s4.3-6,5-8.8s2.7-8.7,3.2-12s4.2-16.2,6.2-20.3c2-4.2,2.8-9.3,6.7-27.3c3.8-18,1.5-22.3,0.5-26.3C121.9,656.9,81,637.8,77.3,638.5z"
                    />
                    <path
                        id="hand_right_back"
                        onClick={() => toggleBodyPart("hand_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "hand_right_back"
                        )}`}
                        d="M677.2,654.3c-2.3-1-7.9-8.1-12.7-10.1c-4.1-1.7-9.8-5-13.5-6.1c-9.3,2.8-35.4,14.5-43.6,19.1
                c-0.2,1.6-0.9,3.1-1.8,5.3c-1.7,4-0.7,16.2,0.3,19.5c1,3.3,4.2,22.3,5.8,26s3,8.2,3.7,10.5s7.7,32,10.2,34.3s5.7,1.8,7-0.5
                s0.5-7.5,0-10.8s-1.7-9.8-2-12.5s-2.3-10.5-3.3-14.2c-1-3.7,1.3-3.7,3-1.5c1.7,2.2,3.3,8.7,4.2,11.8s3.5,16.2,4.3,20.7
                s2.8,17.7,5.8,20.8s5.7,3.3,8.2,1s1.2-7.3,0.8-10.2s-2.5-19.2-2.8-23s-3.8-14.3-4.7-20.5s2.7-1.8,3-0.5s4.2,14.8,4.7,18.3
                s3,15.7,3.5,22.7s3.7,13,4.8,14.5s6,2.2,7.5,0s1.2-5.7,1-9.3s-1.5-22.2-1.5-25.7s-4.5-19.8-5-23.5s1.3-1.8,2-0.2
                c0.7,1.7,5,19.2,5.8,22.8s1.2,7.3,1.8,12s3.8,9,6.3,10.3s5.5-1.2,6.5-3.8s-1.5-15.2-1.8-23.3s-1.5-11.3-2.2-14
                c-0.7-2.7-3-18.2-3.8-22.5c-0.8-4.3-6.7-19-7.7-21.8s4.2,0.5,5.7,2.8s7.5,5.5,10.5,5.3s5.7-1.7,6-5.3s-3.8-4.5-5.8-9.8
                C686.1,659.1,679.5,655.3,677.2,654.3z"
                    />
                    <path
                        id="hamstring_left"
                        onClick={() => toggleBodyPart("hamstring_left")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "hamstring_left"
                        )}`}
                        d="M260.6,720.6c-8-12.1-15.3-18.1-15.3-37.1c-1.5,8.5-8.2,43-9.8,54s-3,14.5-7.2,46.8s-1.2,76,2.8,93.5
                s12.7,36.2,15.2,49.2c14.2,23.2,32,27.7,48.3,28.7c17.7,1.1,33.4-2.9,40.7-15.4c1.7-5.8,6-44.5,8.2-58s9.5-61.3,10.5-78.7
                s1-35,0.2-41s-1.6-16.3-1-21.7C318.7,774.9,281.3,751.9,260.6,720.6z"
                    />
                    <path
                        id="hamstring_right"
                        onClick={() => toggleBodyPart("hamstring_right")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "hamstring_right"
                        )}`}
                        d="M376.7,740.3c0.8,5.8,0.1,10.2-1.2,28.7s3.2,73.2,6.5,86.8s7,38,8.8,56.2c1.1,11.4,3.4,23.1,5.6,32.4
                c8.4,9.2,24.1,12.3,39.7,11.3c15.3-0.9,32.9-5,46.8-24.9c0.9-6,1.7-9.6,2-10.8c0.8-3,10.8-28,13.5-41.2s4.2-43.1,5.2-58.1
                s-4.5-54-5.8-61.7s-3.8-29.7-5.2-35.8s-4.3-21.7-4.8-25.7c-0.2-1.7-1.3-7.5-2.5-14c0,23.5-5.6,24.7-14.1,38.4
                C451.5,753.3,414.4,780.2,376.7,740.3z"
                    />
                    <path
                        id="knee_left_back"
                        onClick={() => toggleBodyPart("knee_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "knee_left_back"
                        )}`}
                        d="M294.5,955.7c-16.2-1-34-5.5-48.3-28.7c2.5,13,3.2,32.2,4.2,53.5c0.7,13.9-0.1,29.7-1.2,42.4
                c2.6-20.9,19.1-19.1,35.6-17.5c16.6,1.7,33.3,3.3,35.7,9.9c-1.2-5.5,0.7-11.2,3-16s3.2-17.8,4-28.8s5.8-24.3,7.5-30.2
                C328,952.8,312.2,956.8,294.5,955.7z"
                    />
                    <path
                        id="knee_right_back"
                        onClick={() => toggleBodyPart("knee_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "knee_right_back"
                        )}`}
                        d="M436,955.7c-15.6,1-31.2-2.1-39.7-11.3c1.3,5.6,2.6,10.3,3.7,13.6c2.8,8.8,2.2,34.2,5.5,40.5
                c2.6,4.9,3.7,9.3,3.5,15.2c6-4.7,22.4-6.8,36.5-8.2c13.7-1.4,29.4,1.3,35.9,14c-1-13.9-1.8-29.9-1.6-46.8c0.1-18.8,1.7-33,3-41.9
                C468.9,950.7,451.3,954.8,436,955.7z"
                    />
                    <path
                        id="calf_left"
                        onClick={() => toggleBodyPart("calf_left")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "calf_left"
                        )}`}
                        d="M285,1005.5c-16.5-1.6-33.1-3.4-35.6,17.5c-0.6,6.7-1.2,12.7-1.8,17.1c-1.8,12.8-5,46-2.5,60s8.8,70.5,9,91.8
                c2.4,11.6,18.5,15.4,31.6,15.9c13.2,0.5,23.3-2.3,25.6-16.1c1.5-12.2,2.9-25.1,3.1-31.6c0.3-11.3,8.3-24,12.8-37.3
                s0.5-46.7-1.2-65.5s-4.2-36.3-5.3-41.8C318.2,1008.7,301.6,1007.1,285,1005.5z"
                    />
                    <path
                        id="calf_right"
                        onClick={() => toggleBodyPart("calf_right")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "calf_right"
                        )}`}
                        d="M445.5,1005.5c-14.2,1.4-30.5,3.5-36.5,8.2c-0.1,1.6-0.2,3.3-0.5,5.1c-1.2,8.8-7,65.3-7.5,84.8s7.5,36,10.5,40
                s3.8,15.5,4,21.8c0.1,3.2,1.8,16.7,3.8,30.9c5.6,7.7,15.9,11.9,25.6,11.5c11.8-0.5,25.9-3.6,30.5-12.6c0.1-3.3,0.3-6.4,0.4-9.1
                c0.8-13,4.8-46.8,7.5-74s3-44.8,1-62.2c-0.9-8.1-2-18.4-2.9-30.2C474.9,1006.8,459.2,1004.1,445.5,1005.5z"
                    />
                    <path
                        id="ankle_left_back"
                        onClick={() => toggleBodyPart("ankle_left_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "ankle_left_back"
                        )}`}
                        d="M285.6,1207.7c-13.1-0.5-29.2-4.4-31.6-15.9c0.2,21.2,4.1,51.5,4.2,58.1s-1.2,26.8-1,28.6s0.2,3.8-0.9,3.8
                c6.1,14.4,4.3,25.2,3.6,34.4c-0.4,5.1,0.2,9.5,1.3,14.2c10.5-13.5,48-20.2,48.2-7.5c-0.2-6.2-1.2-13.5-2.1-20.5
                c-1.5-12.8,1.8-27.3,2.2-31.2s-4.5-18.5-5.8-25.5s2.2-19.2,4.2-31.3c0.9-5.2,2.1-14.1,3.2-23.3
                C308.9,1205.4,298.8,1208.2,285.6,1207.7z"
                    />
                    <path
                        id="ankle_right_back"
                        onClick={() => toggleBodyPart("ankle_right_back")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "ankle_right_back"
                        )}`}
                        d="M444.9,1207.7c-9.7,0.4-19.9-3.8-25.6-11.5c1.9,13.8,4.2,28.2,5.7,34.3c3,12.5,1,17.8-1.8,26.7
                s-2.3,14.3-1.3,22s0.7,17.5,0.5,24.5c-0.1,4.1-2.1,11.3-2.6,18c5.4-8.7,37.8-2.7,48.5,9c0.5-0.5,1-0.9,1.5-1.4
                c0.8-4.1,1.1-8.5,0.8-12.7c-1-13.2-2-21.1,3-33.8c-0.1-0.1-0.2-0.1-0.3-0.2c-2.2-1.5-1.7-12.7-1.7-21.7s1.2-24.2,2.2-32
                c0.8-6.1,1.1-21.4,1.6-33.9C470.8,1204.1,456.6,1207.2,444.9,1207.7z"
                    />
                    <path
                        id="sole_left"
                        onClick={() => toggleBodyPart("sole_left")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "sole_left"
                        )}`}
                        d="M261.3,1330.8c2.3,2.2,12.7,9.2,23.2,11.7s19.2-1.5,23-7.2c1.7-2.6,2.2-6.9,2.1-12
                C309.3,1310.7,271.8,1317.3,261.3,1330.8z"
                    />
                    <path
                        id="sole_right"
                        onClick={() => toggleBodyPart("sole_right")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "sole_right"
                        )}`}
                        d="M419.7,1321.7c-0.4,4.8,0,9.4,2.5,12.3c6.6,7.9,13.3,10.3,26.7,7.2c9.4-2.2,15.7-7.1,19.4-10.4
                C457.5,1319,425.1,1313,419.7,1321.7z"
                    />
                    <path
                        id="foot_left"
                        onClick={() => toggleBodyPart("foot_left")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "foot_left"
                        )}`}
                        d="M260,1316.7c0.7-9.2,2.4-20-3.6-34.4c-1.1,0-2.9,1.4-3.1,2.6s-2.4,0.6-4,0.1s-6.6,4.5-6.8,5.1s-0.2,1.2-2.2,1.1
                s-5.8,3.1-5.9,4.1s-1.2,1.8-2.9,2s-4.2,3-5.2,5.3s0.8,4.8,1.7,9.2s6.7,9.3,18.8,12.2s12.2,4.7,14.5,6.8
                C260.2,1326.2,259.6,1321.8,260,1316.7z"
                    />
                    <path
                        id="foot_right"
                        onClick={() => toggleBodyPart("foot_right")}
                        className={`body__clickable ${isActiveClass(
                            activeParts,
                            "foot_right"
                        )}`}
                        d="M470.5,1316.7c0.3,4.2,0,8.7-0.8,12.7c0.8-0.8,1.4-1.4,1.8-1.7c2.2-1.8,16-5.7,21.5-7.5s7.3-7.2,7.7-10.2
                s0.5-2.7,1.8-5.8s-5.2-7.5-6-7.5s-2,0-2-1.5s-3.7-4.3-5.2-4.3s-3-1-3.5-2.5s-6.7-3.8-8.8-3.5c-2.1,0.3-1.7-0.6-3.5-1.9
                C468.5,1295.5,469.5,1303.5,470.5,1316.7z"
                    />
                    <g id="bck_spots"/>
                </svg>
            </div>
        </div>
    );
};
