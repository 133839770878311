import { EnumValues } from "@firecms/core";

export const bodyPartsEnum: EnumValues = {
    abdomen: "Abdomen",
    ankle_left_back: "Ankle left back",
    ankle_left_front: "Ankle left front",
    ankle_right_back: "Ankle right back",
    ankle_right_front: "Ankle right front",
    arm_left_back: "Arm left back",
    arm_left_front: "Arm left front",
    arm_right_back: "Arm right back",
    arm_right_front: "Arm right front",
    back: "Back",
    buttocks: "Buttocks",
    calf_left: "Calf left",
    calf_right: "Calf right",
    chest: "Chest",
    elbow_left_back: "Elbow left back",
    elbow_left_front: "Elbow left front",
    elbow_right_back: "Elbow right back",
    elbow_right_front: "Elbow right front",
    foot_left: "Foot left",
    foot_right: "Foot right",
    forearm_left_back: "Forearm left back",
    forearm_left_front: "Forearm left front",
    forearm_right_back: "Forearm right back",
    forearm_right_front: "Forearm right front",
    hamstring_left: "Hamstring left",
    hamstring_right: "Hamstring right",
    hand_left_back: "Hand left back",
    hand_left_front: "Hand left front",
    hand_right_back: "Hand right back",
    hand_right_front: "Hand right front",
    head_back: "Head back",
    head_front: "Head front",
    knee_left_back: "Knee left back",
    knee_left_front: "Knee left front",
    knee_right_back: "Knee right back",
    knee_right_front: "Knee right front",
    loin: "Loin",
    neck_back: "Neck back",
    neck_front: "Neck front",
    pelvis: "Pelvis",
    pubis: "Pubis",
    shin_left: "Shin left",
    shin_right: "Shin right",
    shoulder_left_back: "Shoulder left back",
    shoulder_left_front: "Shoulder left front",
    shoulder_right_back: "Shoulder right back",
    shoulder_right_front: "Shoulder right front",
    sole_left: "Sole left",
    sole_right: "Sole right",
    thigh_left: "Thigh left",
    thigh_right: "Thigh right",
    wrist_left_back: "Wrist left back",
    wrist_left_front: "Wrist left front",
    wrist_right_back: "Wrist right back",
    wrist_right_front: "Wrist right front"
};


export const bodyPartsMappedEnum: Record<string, string> = {
    "abdomen": "abdomen",
    "ankle": "ankle",
    "arm": "arm",
    "back": "back",
    "buttocks": "buttocks",
    "lower_leg": "lower_leg",
    "chest": "chest",
    "elbow": "elbow",
    "foot": "foot",
    "forearm": "forearm",
    "hamstring": "hamstring",
    "hand": "hand",
    "head": "head",
    "knee": "knee",
    "loin": "loin",
    "neck": "neck",
    "pelvis": "pelvis",
    "pubis": "pubis",
    "shoulder": "shoulder",
    "sole": "sole",
    "thigh": "thigh",
    "wrist": "wrist",
};
export const bodyPartsMap: Record<string, string> = {
    "abdomen": "abdomen",
    "ankle_left_back": "ankle",
    "ankle_left_front": "ankle",
    "ankle_right_back": "ankle",
    "ankle_right_front": "ankle",
    "arm_left_back": "arm",
    "arm_left_front": "arm",
    "arm_right_back": "arm",
    "arm_right_front": "arm",
    "back": "back",
    "buttocks": "buttocks",
    "calf_left": "lower_leg",
    "calf_right": "lower_leg",
    "chest": "chest",
    "elbow_left_back": "elbow",
    "elbow_left_front": "elbow",
    "elbow_right_back": "elbow",
    "elbow_right_front": "elbow",
    "foot_left": "foot",
    "foot_right": "foot",
    "forearm_left_back": "forearm",
    "forearm_left_front": "forearm",
    "forearm_right_back": "forearm",
    "forearm_right_front": "forearm",
    "hamstring_left": "hamstring",
    "hamstring_right": "hamstring",
    "hand_left_back": "hand",
    "hand_left_front": "hand",
    "hand_right_back": "hand",
    "hand_right_front": "hand",
    "head_back": "head",
    "head_front": "head",
    "knee_left_back": "knee",
    "knee_left_front": "knee",
    "knee_right_back": "knee",
    "knee_right_front": "knee",
    "loin": "loin",
    "neck_back": "neck",
    "neck_front": "neck",
    "pelvis": "pelvis",
    "pubis": "pubis",
    "shin_left": "lower_leg",
    "shin_right": "lower_leg",
    "shoulder_left_back": "shoulder",
    "shoulder_left_front": "shoulder",
    "shoulder_right_back": "shoulder",
    "shoulder_right_front": "shoulder",
    "sole_left": "sole",
    "sole_right": "sole",
    "thigh_left": "thigh",
    "thigh_right": "thigh",
    "wrist_left_back": "wrist",
    "wrist_left_front": "wrist",
    "wrist_right_back": "wrist",
    "wrist_right_front": "wrist"
};

export const bodyPartsMirroringMap: Record<string, string[]> = {
    "abdomen": ["abdomen"],
    "ankle_left_back": ["ankle_left_back", "ankle_right_back"],
    "ankle_right_back": ["ankle_left_back", "ankle_right_back"],
    "ankle_left_front": ["ankle_left_front", "ankle_right_front"],
    "ankle_right_front": ["ankle_left_front", "ankle_right_front"],
    "arm_left_back": ["arm_left_back", "arm_right_back"],
    "arm_right_back": ["arm_left_back", "arm_right_back"],
    "arm_left_front": ["arm_left_front", "arm_right_front"],
    "arm_right_front": ["arm_left_front", "arm_right_front"],
    "back": ["back"],
    "buttocks": ["buttocks"],
    "calf_left": ["calf_left", "calf_right"],
    "calf_right": ["calf_left", "calf_right"],
    "chest": ["chest"],
    "elbow_left_back": ["elbow_left_back", "elbow_right_back"],
    "elbow_right_back": ["elbow_left_back", "elbow_right_back"],
    "elbow_left_front": ["elbow_left_front", "elbow_right_front"],
    "elbow_right_front": ["elbow_left_front", "elbow_right_front"],
    "foot_left": ["foot_left", "foot_right"],
    "foot_right": ["foot_left", "foot_right"],
    "forearm_left_back": ["forearm_left_back", "forearm_right_back"],
    "forearm_right_back": ["forearm_left_back", "forearm_right_back"],
    "forearm_left_front": ["forearm_left_front", "forearm_right_front"],
    "forearm_right_front": ["forearm_left_front", "forearm_right_front"],
    "hamstring_left": ["hamstring_left", "hamstring_right"],
    "hamstring_right": ["hamstring_left", "hamstring_right"],
    "hand_left_back": ["hand_left_back", "hand_right_back"],
    "hand_right_back": ["hand_left_back", "hand_right_back"],
    "hand_left_front": ["hand_left_front", "hand_right_front"],
    "hand_right_front": ["hand_left_front", "hand_right_front"],
    "head_back": ["head_back"],
    "head_front": ["head_front"],
    "knee_left_back": ["knee_left_back", "knee_right_back"],
    "knee_right_back": ["knee_left_back", "knee_right_back"],
    "knee_left_front": ["knee_left_front", "knee_right_front"],
    "knee_right_front": ["knee_left_front", "knee_right_front"],
    "loin": ["loin"],
    "neck_back": ["neck_back"],
    "neck_front": ["neck_front"],
    "pelvis": ["pelvis"],
    "pubis": ["pubis"],
    "shin_left": ["shin_left", "shin_right"],
    "shin_right": ["shin_left", "shin_right"],
    "shoulder_left_back": ["shoulder_left_back", "shoulder_right_back"],
    "shoulder_right_back": ["shoulder_left_back", "shoulder_right_back"],
    "shoulder_left_front": ["shoulder_left_front", "shoulder_right_front"],
    "shoulder_right_front": ["shoulder_left_front", "shoulder_right_front"],
    "sole_left": ["sole_left", "sole_right"],
    "sole_right": ["sole_left", "sole_right"],
    "thigh_left": ["thigh_left", "thigh_right"],
    "thigh_right": ["thigh_left", "thigh_right"],
    "wrist_left_back": ["wrist_left_back", "wrist_right_back"],
    "wrist_right_back": ["wrist_left_back", "wrist_right_back"],
    "wrist_left_front": ["wrist_left_front", "wrist_right_front"],
    "wrist_right_front": ["wrist_left_front", "wrist_right_front"]
};


export const bodyPartsMapReversed: Record<string, string[]> = {};
Object.entries(bodyPartsMap)
    .forEach(([key, value]) => {
        if (bodyPartsMapReversed[value])
            bodyPartsMapReversed[value].push(key);
        else
            bodyPartsMapReversed[value] = [key];
    });
