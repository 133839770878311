import React from "react";
import { MapProperty, Property, PropertyPreview, PropertyPreviewProps } from "@firecms/core";
import { Table, TableBody, TableCell, TableRow } from "@firecms/ui";

export default function ExerciseStrategyPreview({
                                                    value, property, size
                                                }: PropertyPreviewProps<object[]>
) {
    const mapProperty = property.of as MapProperty;
    if (!mapProperty.properties) return null;

    return (
        <Table>
            {/*<TableHeader>*/}
            {/*    {Object.entries(mapProperty.properties).map(*/}
            {/*        ([key, childProperty]) => {*/}
            {/*            return <TableCell header scope="col">{childProperty.name ?? key}</TableCell>*/}
            {/*        }*/}
            {/*    )}*/}
            {/*</TableHeader>*/}
            <TableBody>
                {value.map((row, index) => {
                    return (
                        <TableRow key={index}>
                            {Object.entries(mapProperty.properties as any).map(
                                ([key, childProperty]) => {
                                    return <TableCell><PropertyPreview propertyKey={key}
                                                                       property={childProperty as Property}
                                                                       size={"small"}
                                                                       value={(row as any)?.[key]}
                                    /></TableCell>
                                }
                            )}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )

}
