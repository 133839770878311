export const firebaseConfig = {
    "projectId": "medicalmotion-df799",
    "appId": "1:787885768884:web:565d51f05e781f8c1f17e1",
    "databaseURL": "https://medicalmotion-df799.firebaseio.com",
    "storageBucket": "medicalmotion-df799.appspot.com",
    "locationId": "europe-west",
    "apiKey": "AIzaSyCy95GRVJxHyngWiHPXWPc4Af5a9EWm7pc",
    "authDomain": "medicalmotion-df799.firebaseapp.com",
    "messagingSenderId": "787885768884",
    "measurementId": "G-SEGQVDZG3Y"
}
// export const firebaseConfig = {
//     apiKey: "AIzaSyCVncryqtX57bJH0-x0pBMCZ36HyA8L-uY",
//     authDomain: "medicalmotion-staging.firebaseapp.com",
//     databaseURL: "https://medicalmotion-staging.firebaseio.com",
//     projectId: "medicalmotion-staging",
//     storageBucket: "medicalmotion-staging.appspot.com",
//     messagingSenderId: "29443452322",
//     appId: "1:29443452322:web:c0aad96e5b07a8eaf896fb",
//     measurementId: "G-W7PM5XEQBS"
// };
